<template>
  <div class="Business">
    <van-popup
      v-model="this.$store.state.show"
      position="right"
      :style="{ height: '100%', width: '223px' }"
      class="genduomenu"
    >
      <el-input
        suffix-icon="el-icon-search"
        class="PeSearch"
        v-model="searchText"
      >
      </el-input>
      <ul class="peMenu">
        <li @click="toHome">
          <span>首页</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toBusiness">
          <span>经营范围</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toNews">
          <span>新闻资讯</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toAdvantage">
          <span>核心优势</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toGroup_Introduction">
          <span>集团介绍</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toContact_us">
          <span>联系我们</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </van-popup>
    <BusinessHeader @click="popHide"></BusinessHeader>
    <div class="layer1" @click="popHide">
      <div class="titles">
        <h2>
          {{ $t('Business.Advantage') }}<span>/</span>
          <span>{{ $t('Business.AdvantageE') }}</span>
        </h2>
      </div>
      <el-divider class="divider"></el-divider>
      <ul class="content">
        <li @click="toNewEnergy">
          <div class="slogan">{{ $t('Business.NewEnergy') }}</div>
        </li>
        <li @click="toCar">
          <div class="slogan">{{ $t('Business.Car') }}</div>
        </li>
        <li @click="toIndustrial">
          <div class="slogan">{{ $t('Business.Industry') }}</div>
        </li>
        <li @click="toCommunication">
          <div class="slogan">{{ $t('Business.Communication') }}</div>
        </li>
        <li @click="toInstrument">
          <div class="slogan">{{ $t('Business.InstrumentAndMeter') }}</div>
        </li>
        <li @click="toMotor">
          <div class="slogan">{{ $t('Business.ElectricMachinery') }}</div>
        </li>
        <li @click="toRobot">
          <div class="slogan">{{ $t('Business.RobotControl') }}</div>
        </li>
        <li @click="toElectronic">
          <div class="slogan">{{ $t('Business.ElectronicProduct') }}</div>
        </li>
      </ul>
      <h2 class="plz">
        {{ $t('Business.Plz')
        }}<span @click="toContact_us"> {{ $t('Business.ContactUs') }}</span>
      </h2>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import BusinessHeader from '../../components/BusinessHeader.vue'
import footers from '../../components/footer.vue'

export default {
  components: {
    BusinessHeader,
    footers
    // popMenu
  },
  data () {
    return {
      searchText: ''
    }
  },
  created () {
    this.$store.commit('setShow', false)
  },
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    toNewEnergy () {
      this.$router.push('/NewEnergy')
    },
    toCar () {
      this.$router.push('/Car')
    },
    toIndustrial () {
      this.$router.push('/Industrial')
    },
    toCommunication () {
      this.$router.push('/Communication')
    },
    toInstrument () {
      this.$router.push('/Instrument')
    },
    toMotor () {
      this.$router.push('/Motor')
    },
    toRobot () {
      this.$router.push('/Robot')
    },
    toElectronic () {
      this.$router.push('/Electronic')
    },
    toNews () {
      this.$router.push('/News')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    popHide () {
      this.$store.commit('setShow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.Business {
  width: 100%;
  .layer1 {
    width: 1640px;
    margin: 0 auto;
    .titles {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      line-height: 80px;
      padding-bottom: 60px;
      margin-top: 90px;
      h2 {
        display: block;
        width: 960px;
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        display: flex;
        align-items: center;
      }
      span {
        font-size: 16px;
        font-family: Didot, Didot-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        &:nth-child(1) {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
    .content {
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 533px;
        height: 500px;
        background: hotpink;
        margin-bottom: 12px;
        list-style: none;
        position: relative;
        .slogan {
          // width: 254px;
          padding: 0 25px;
          height: 66px;
          line-height: 66px;
          background: rgba(255, 255, 255, 0.3);
          border-bottom-left-radius: 5px;
          -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
          position: absolute;
          color: #fff;
          right: 0;
          text-align: center;
          font-size: 30px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          color: #ffffff;
        }
        &:nth-child(1) {
          background: url('../../assets/PE/Business/329.png');
          background-size: cover;
        }
        &:nth-child(2) {
          background: url('../../assets/PE/Business/330.png');
          background-size: cover;
        }
        &:nth-child(3) {
          background: url('../../assets/PE/Business/331.png');
          background-size: cover;
        }
        &:nth-child(4) {
          background: url('../../assets/Business/214.png');
          background-size: cover;
        }
        &:nth-child(5) {
          background: url('../../assets/PE/Business/333.png');
          background-size: cover;
        }
        &:nth-child(6) {
          background: url('../../assets/PE/Business/334.png');
          background-size: cover;
        }
        &:nth-child(7) {
          background: url('../../assets/PE/Business/336.png');
          background-size: cover;
        }
        &:nth-child(8) {
          background: url('../../assets/PE/Business/335.png');
          background-size: cover;
        }
      }
    }
    .content::after {
      content: '';
      width: 533px;
    }
    .plz {
      font-size: 40px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      text-align: center;
      color: #333333;
      line-height: 84px;
      padding-top: 90px;
      span {
        color: #4090e7;
      }
    }
  }
  .footer {
    background: url('../../assets/Home/bottom.png');
    background-size: cover;
    height: 470px;
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .top {
      width: 1400px;
      height: 40px;
      margin: 0 auto;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 41px;
      }
      .right {
        display: flex;
        justify-content: space-between;
        li {
          margin-left: 100px;
          display: flex;
          align-items: center;
        }
      }
    }
    .bottom {
      width: 1400px;
      height: 32px;
      margin: 0 auto;
      margin-top: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .first {
        display: flex;
        align-items: center;
        .left {
          margin-right: 10px;
        }
      }
      .last {
        display: flex;
        align-items: center;
        * {
          margin-left: 30px;
        }
        span {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Business {
    width: 100%;
    .layer1 {
      // display: none;
      width: 100%;
      margin: 0 auto;
      .divider {
        display: none;
      }
      .titles {
        width: 335px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding-bottom: 0px;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
        h2 {
          width: 335px;
          margin: 0 auto;
          display: block;
          width: 360px;
          font-size: 15px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          display: flex;
          align-items: center;
        }
        span {
          font-size: 15px;
          font-family: Didot, Didot-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          &:nth-child(1) {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
      .content {
        width: 335px;
        margin: 0 auto;
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-top: 1px solid #ddd;
        padding-top: 20px;
        li {
          width: 160px;
          height: 112.5px;
          background: hotpink;
          margin-bottom: 12px;
          list-style: none;
          position: relative;
          .slogan {
            // width: 254px;
            padding: 0 10px;
            height: 25px;
            line-height: 25px;
            background: rgba(255, 255, 255, 0.3);
            border-bottom-left-radius: 5px;
            -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
            position: absolute;
            color: #fff;
            right: 0;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            color: #ffffff;
          }
          &:nth-child(1) {
            background: url('../../assets/Business/223.png');
            background-size: cover;
          }
          &:nth-child(2) {
            background: url('../../assets/Business/216.png');
            background-size: cover;
          }
          &:nth-child(3) {
            background: url('../../assets/Business/222.png');
            background-size: cover;
          }
          &:nth-child(4) {
            background: url('../../assets/Business/214.png');
            background-size: cover;
          }
          &:nth-child(5) {
            background: url('../../assets/Business/217.png');
            background-size: cover;
          }
          &:nth-child(6) {
            background: url('../../assets/Business/219.png');
            background-size: cover;
          }
          &:nth-child(7) {
            background: url('../../assets/Business/220.png');
            background-size: cover;
          }
          &:nth-child(8) {
            background: url('../../assets/Business/221.png');
            background-size: cover;
          }
        }
      }
      .content::after {
        content: '';
        width: 533px;
      }
      .plz {
        font-size: 13px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 24px;
        padding-top: 0px;
        margin-bottom: 71.5px;
        span {
          color: #4090e7;
        }
      }
    }
    .footer {
      background: url('../../assets/Home/bottom.png');
      background-size: cover;
      height: 470px;
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .top {
        width: 1400px;
        height: 40px;
        margin: 0 auto;
        padding-top: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          height: 41px;
        }
        .right {
          display: flex;
          justify-content: space-between;
          li {
            margin-left: 100px;
            display: flex;
            align-items: center;
          }
        }
      }
      .bottom {
        width: 1400px;
        height: 32px;
        margin: 0 auto;
        margin-top: 92px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .first {
          display: flex;
          align-items: center;
          .left {
            margin-right: 10px;
          }
        }
        .last {
          display: flex;
          align-items: center;
          * {
            margin-left: 30px;
          }
          span {
            font-size: 20px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
